.choice{
  &__counter{
    font-weight: 600;
    font-size: 100px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 40px;
    min-height: 180px;
  }
  &__winner{
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 40px;
    @media ( max-width: 575px ){
      font-size: 18px;
    }
  }
  &__buttons{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    justify-content: space-between;
    @media ( max-width: 767px ){
      justify-content: space-around;
      margin-top: 300px;
    }
    @media ( max-width: 575px ){
      margin-top: 200px;
    }
    @media ( max-width: 431px ){
      margin-top: 80px;
    }
  }
  &__buttonsWinns{
    margin-top: 80px;
    @media ( max-width: 575px ){

    }
  }
}
