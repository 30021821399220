.ellipse{
  position: fixed;
  z-index: -1;
  width: 510px;
  height: 510px;
  filter: blur(125px);
  @media ( max-width: 767px ){
    width: 400px;
    height: 400px;
  }
  @media ( max-width: 431px ){
    width: 350px;
    height: 350px;
  }
  &__purple{
    left: -233px;
    top: 28px;
    background: #783D7D;
    @media (max-width: 767px) {
      left: -280px;
    }
    @media ( max-width: 575px ){
      left: -320px;
    }
    @media ( max-width: 431px ){
      left: -340px;
    }
  }
  &__yellow{
    left: 1015px;
    top: 152px;
    background: #907348;
    @media (max-width: 767px) {
      left: 700px;
    }
    @media ( max-width: 575px ){
      left: 620px;
    }
    @media ( max-width: 431px ){
      left: 258px;
    }
  }
}
