* {
  font-family: 'TT Firs Neue', sans-serif;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  background: #1F1F1F;
  overflow-x: hidden;
  margin: 0 auto;
}

