.container {
  max-width: 800px;
  margin: 0 auto;
  @media ( max-width: 991px ){
    max-width: 670px;
  }
  @media ( max-width: 767px ){
    max-width: 560px;
  }
  @media ( max-width: 575px ){
    max-width: 470px;
  }
  @media ( max-width: 431px ){
    max-width: 370px;
  }
}
