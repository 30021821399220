.title {
  font-weight: 600;
  font-size: 48px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    font-size: 46px;
  }
  @media (max-width: 767px) {
    font-size: 42px;
  }
  @media (max-width: 575px) {
    font-size: 38px;
  }
  @media (max-width: 431px) {
    font-size: 30px;
  }
}
