.input {
  &__group {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;
  }
  &__label {
    font-size: 18px;
    color: #ffffff;
  }
  &__item {
    height: 48px;
    background-color: #1F1F1F;
    color: #ffffff;
    border-radius: 8px;
    border: 1px solid #1F1F1F;
    &:focus {
      border-color: #0034ff;
    }
  }
  &__error {
    display: block;
    line-height: 16px;
    color: #ff0000;
    letter-spacing: 1.5px;
    margin: 8px 8px 0;
  }
  &__editor {
    background-color: #c0bcbc;
  }
}
