.header {
  &__front {
    margin: 0 auto;
    padding: 40px 0 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__logo {
    margin-right: 100px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 991px) {
    &__front {
      padding: 30px 0 50px 0;
    }
    &__logo {
      margin-right: 50px;
    }
  }
  @media (max-width: 767px) {
      padding: 23px 150px;
      &__logo {
        margin-right: 50px;
        img {
          width: 100%;
        }
      }
    }
  @media (max-width: 575px) {
      padding: 23px 90px;
      &__logo {
        margin-right: 20px;
      }
    }
  }
