.button{
  height: 70px;
  width: 100%;
  box-shadow: 0 43px 101px rgb(249 192 34 / 20%), 0 4px 0 #ab800a, inset 0 0 20px rgb(255 249 230 / 35%);
  background-image: linear-gradient(to top, #fdcc43 0, #fce18b 100%);
  border-radius: 20px;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #1F1F1F;
  cursor: pointer;
  border: none;
  @media (max-width: 767px){
    width: 360px;
    margin: 0 auto 50px;
  }
  @media ( max-width: 575px ){
    height: 50px;
  }
  @media ( max-width: 431px ){
    width: 350px;
    height: 50px;
  }
  &__index{
    margin: 0 auto;
    width: 214px;
  }
  &__list{
    max-width: 334px;
    margin: 0 63px;
  }
  &__change{
    width: 235px;
    box-shadow: 0 43px 101px rgb(249 192 34 / 20%), 0 4px 0 #917016, inset 0 0 20px rgb(255 249 230 / 35%);
    background-image: linear-gradient(to top, #ffac0b 0, #ffc85d 100%)

  }
  &__random{
    width: 235px;
    box-shadow: 0 43px 101px rgb(249 192 34 / 20%), 0 4px 0 #ab800a, inset 0 0 20px rgb(255 249 230 / 35%);
    background-image: linear-gradient(to top, #fdcc43 0, #fce18b 100%);

  }
  &__next{
    width: 235px;
    box-shadow: 0 43px 101px #6191245c, 0 4px 0 #619124, inset 0 0 20px rgb(255 249 230 / 35%);
    background-image: linear-gradient(to top, #75bf18 0, #91d23e 100%);

    @media ( max-width: 767px ){
      margin-bottom: 83px;
    }
  }
  &__set{
    display: block;
    width: 133px;
    height: 39px;
    margin-top: 63px;
    border-radius: 0;
    background: #91D23E;
    font-weight: 400;
    font-size: 16px;
  }
  &__complete{
    margin: 46px auto 55px;
    color: #fff;
    width: 235px;
    box-shadow: 0 43px 101px #742828, 0 4px 0 #541313, inset 0 0 20px rgb(255 249 230 / 35%);
    background-image: linear-gradient(to top, #772525 0, #b13b3b 100%);
    @media (max-width: 991px){
      margin: 0;
    }
    @media ( max-width: 767px ){
      margin: 0;
    }
    @media (max-width: 575px){
      margin-bottom: 30px;
    }
    @media ( max-width: 431px ){
      margin-bottom: 55px;
    }
    }
  &__completeAdmin{
    width: 215px;
    height: 39px;
    color: #fff;
    background: #772525;
    border: 1px solid #772525;
    border-radius: 0;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 16px;
  }
  &__winnerAdmin{
    width: 133px;
    height: 39px;
    background: #FFBB36;
    border-radius: 0;
    border: none;
    margin-right: 25px;
    font-weight: 400;
    font-size: 16px;
  }
  &__deleteAdmin{
    width: 212px;
    height: 39px;
    background: #772525;
    border: none;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    @media (max-width: 431px) {
      width: 133px;
    }
  }
}
