.participants {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 20px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 40px;
}
