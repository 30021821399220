.form__top{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}

.form__bottom{
  display: flex;
  flex-direction: column;
  label{
    font-size: 18px;
  }
}

.shell {
  display: block;
  position: relative;
  padding-left: 44px;
  margin-bottom: 23px;
  cursor: pointer;
  user-select: none;
  color: #ffffff;
  .real {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .castom {
      background-color: #1F1F1F;
      &:after {
        display: block;
      }
    }
  }
  &:hover .real ~ .castom {
    background-color: #1F1F1F;
  }
  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
.castom {
  position: absolute;
  top: -5px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #1F1F1F;
  border: 1px solid #FFFFFF;
  vertical-align :middle;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
  &:after {
    left: 9px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
