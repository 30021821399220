.settings {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  &__checkboxes {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 23px;
    margin: 40px 0;
  }
  &__checkbox_line {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 82px;
  }
}

.admin__descr{
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}
.form{
  &__footer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 109px;
  }
}
.select{
  width: 331px;
  height: 133px;
  margin-top: 9px;
  padding: 16px 24px;
  background: #1F1F1F;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  overflow-y: hidden;
  option{
    margin-bottom: 11px;
    font-size: 18px;
    cursor: pointer;
  }
}
.settings_input {
  height: 18px;
  background: #1f1f1f;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 9px 18px;
  margin-left: 30px;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  @media (max-width: 431px) {
    width: 150px;
    margin-left: 15px;
  }
}
