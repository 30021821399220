.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title{
    font-weight: 600;
    font-size: 48px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 40px;
    @media (max-width: 991px){
      font-size: 46px;
    }
    @media (max-width: 767px){
      font-size: 42px;
    }
    @media ( max-width: 575px ){
      font-size: 38px;
    }
    @media ( max-width: 431px ){
      font-size: 30px;
    }
  }
  &__descr{
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
    @media (max-width: 767px){
      font-size: 20px;
    }
    @media ( max-width: 575px ){
      font-size: 18px;
    }
    @media ( max-width: 431px ){
      font-size: 16px;
    }
  }
  &__form{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 550px;
    @media (max-width: 800px) {
      max-width: 460px;
    }
  }
  &__button {
    margin-top: 56px;
  }
}
.input{
  height: 54px;
  margin-bottom: 20px;
  background: #1F1F1F;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  padding: 0 30px;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  &__log{
    margin-bottom: 57px;
  }
  @media ( max-width: 767px ){
    width: 340px;
  }
  @media (max-width: 767px){
    width: 298px;
    margin: 10px auto;
  }
  @media (max-width: 431px){
    width: 288px;
  }
}
.select{
  width: 460px;
  height: 54px;
  margin-bottom: 20px;
  padding: 0 30px;
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  overflow-y: hidden;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
  appearance: none;
  background: #1F1F1F url("../../ico/Vector.svg") no-repeat right;
  background-position-x: calc(100% - 30px);
  &:nth-of-type(2){
    margin-bottom: 40px;
  }
  @media ( max-width: 767px ){
    width: 360px;
    margin: 10px auto;
  }
  @media (max-width: 431px){
    width: 350px;
  }
}

.title {
  color: #ffffff;
  margin-bottom: 40px;
}

.modal {
  display: none;
  position: fixed;
  top: 40px;
  right: 16px;
  bottom: 40px;
  left: 16px;
  margin: auto;
  z-index: 110;
  &--show {
    display: block;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__content {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 150px;
    background-color: #fff;
    overflow-y: auto;
    padding: 40px;
  }
  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    margin: auto;
    cursor: pointer;
    &:before, &:after {
      position: absolute;
      left: 11px;
      content: "";
      height: 16px;
      width: 2px;
      background-color: #000;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__data {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    row-gap: 16px;
  }
  &__title {
    font-size: 24px;
  }
  &__text {
    font-size: 24px;
  }
  &__number {
    font-size: 32px;
    font-weight: 600;
  }
}
