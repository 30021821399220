.header{
  position: relative;
  height: 22px;
  padding: 23px 200px;
  background: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991px) {
    padding: 23px 100px;
  }
  @media (max-width: 575px) {
    padding: 23px 50px;
  }
  @media (max-width: 431px) {
    padding: 23px 20px;
  }
  &__burger{
    width: 32px;
    height: 22px;
    span {
      display: block;
      height: 3px;
      background: #FFFFFF;
      margin-bottom: 6px;
      &:nth-child(1){
        width: 24px;
      }
      &:nth-child(3){
        width: 18px;
      }
    }
  }
  .nav{
    display: none;
    position: absolute;
    top: 68px;
    left: 172px;
    width: 243px;
    height: 232px;
    background: black;
    padding: 30px;
    z-index: 2;
    &_show {
      display: block;
    }
    @media (max-width: 991px) {
      left: 75px;
    }
    @media (max-width: 575px) {
      left: 40px;
      width: 175px;
      height: 220px;
    }
    @media (max-width: 431px) {
      left: 0;
    }
    &__link{
      display: block;
      margin-bottom: 30px;
      font-weight: 400;
      font-size: 18px;
      color: white;
      cursor: pointer;
    }
    @media (max-width: 575px) {
      font-size: 16px;
    }
  }
  a{
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    text-decoration: none;
  }
  img {
    max-width: 100%;
  }
}
