.registration {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title{
    font-weight: 600;
    font-size: 48px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 40px;
  }
  &__descr{
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 40px;
  }
}
