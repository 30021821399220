.descr{
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 40px;
  @media (max-width: 767px){
    font-size: 20px;
  }
  @media ( max-width: 575px ){
    font-size: 18px;
  }
  @media ( max-width: 431px ){
    font-size: 16px;
  }
}

