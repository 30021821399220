.input {
  &__group {
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 20px;
    align-items: center;
  }
  &__item {
    display: none;
  }
  &__label {
    color: #ffffff;
  }
  &__checkbox {
    position: relative;
    height: 24px;
    width: 24px;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    &--checked {
      &:before {
        content: '';
        position: absolute;
        left: 9px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
    }
  }
}
