.form__index{
  display: flex;
  flex-direction: column;
  width: 460px;
  margin: 80px auto;
  @media ( max-width: 767px ){
    width: 400px;
    margin: 200px auto;
  }
  @media ( max-width: 575px ){
    width: 360px;
  }
  @media ( max-width: 431px ){
    width: 350px;
  }
}
.form__button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
